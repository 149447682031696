















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { getVipInfo, getPushCoinCharterInfo } from '@/pages/videoGame/api'
import { getAppLanguage, isIOS } from '@/common/utils'
import icon_vip from '@/pages/videoGame/assets/icon-vip.png'
import {
  callAppFunc_GoWallet,
  callAppFunc_goRoomList
} from '@/common/utils/bridge'
@Component
export default class extends Vue {
  icon_vip = icon_vip
  seclectedLevel = 0
  vipInfo: any = {
    totalRechargeMoney: 0,
    nextVipLevelRechargeThreshold: 0,
    vipLevelRechargeThreshold: 0
  }
  language = getAppLanguage()
  startX: any = 0
  startY: any = 0
  endX: any = 0
  endY: any = 0
  index = 0
  canMove = true
  vipConfigs: any = []
  vipRights: any = []

  showCharterCard = false
  charterInfo: any = {}
  weekDayMap: any = {
    1: '每周一',
    2: '每周二',

    3: '每周三',
    4: '每周四',
    5: '每周五',
    6: '每周六',
    7: '每周日'
  }
  weekDayMapUg: any = {
    1: '每周一',
    2: '每周二',

    3: '每周三',
    4: '每周四',
    5: '每周五',
    6: '每周六',
    7: '每周日'
  }
  get machineNames() {
    return this.$t('实体推币机')
    // if (this.charterInfo.machineList) {
    //   if (this.language == 'zh_CN') {
    //     return this.charterInfo.machineList
    //       .map((item: any) => item.machineName)
    //       .join('、')
    //   } else {
    //     return this.charterInfo.machineList
    //       .map((item: any) => item.machineNameUg)
    //       .join('、')
    //   }
    // } else {
    //   return ''
    // }
  }
  formatNum(num: number) {
    return Number(num.toFixed(2))
  }
  get curTarget() {
    return this.formatNum(
      this.vipInfo.nextVipLevelRechargeThreshold -
        this.vipInfo.vipLevelRechargeThreshold
    )
  }
  get curProgress() {
    return this.formatNum(
      this.vipInfo.totalRechargeMoney - this.vipInfo.vipLevelRechargeThreshold
    )
  }
  get curDiff() {
    return this.formatNum(
      this.vipInfo.nextVipLevelRechargeThreshold -
        this.vipInfo.totalRechargeMoney
    )
  }

  get nextLevelText() {
    if (this.language === 'zh_CN') {
      const curDiff = this.curDiff
      const nextVipLevelName = this.vipInfo.nextVipLevelName || ''
      return `再充值${curDiff}元，可升级至${nextVipLevelName}`
    } else {
      const curDiff = this.curDiff
      const nextVipLevelName = this.vipInfo.nextVipLevelName
      return `yana ${curDiff} yuan kaqilisigiz，${nextVipLevelName} ga osisiz`
    }
  }
  isLock(item: any, equity: any) {
    if (
      (!item.machineId && equity.id == 2) ||
      (!item.rechargePresentRate && equity.id == 3) ||
      (!item.exchangeShop && equity.id == 5) ||
      (!item.enterRoomInform && equity.id == 7) ||
      (!item.onlineInform && equity.id == 6) ||
      (!item.presentPushCoinCharterNum && equity.id == 8)
    ) {
      return true
    }
    return false
  }
  levelChange(level: any) {
    this.seclectedLevel = level.id
    const index = this.vipConfigs.findIndex((item: any) => item.id === level.id)
    console.log('levelChange index', index)
    ;(this.$refs.equitySwipeRef as any).swipeTo(index)
  }
  equityChange(index: any) {
    this.seclectedLevel = this.vipConfigs[index].id
    const tab = document.getElementById('viptab_' + this.seclectedLevel)
    tab?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    })
  }

  async getVipData() {
    const { data } = await getVipInfo()
    this.vipInfo = data
    data.vipConfigs.sort((a: any, b: any) => a.id - b.id)
    data.vipRights.sort((a: any, b: any) => a.sort - b.sort)

    this.vipConfigs = data.vipConfigs
    this.vipRights = data.vipRights

    this.seclectedLevel = data.vipLevelId || this.vipConfigs[0].id
    await this.$nextTick()
    const index = this.vipConfigs.findIndex(
      (item: any) => item.id === this.seclectedLevel
    )
    console.log('getVipData index', index)
    ;(this.$refs.equitySwipeRef as any).swipeTo(index)
    this.getCardInfo()
  }
  goWallet() {
    if (isIOS) {
      callAppFunc_GoWallet()
    } else {
      this.$router.push('/wallet')
    }
  }
  async getCardInfo() {
    const { data } = await getPushCoinCharterInfo({
      vipId: this.vipInfo.vipLevelId
    })
    this.charterInfo = data
  }
  handleClick(item: any, equity: any) {
    if (equity.id == 8 && item.presentPushCoinCharterNum) {
      this.showCharterCard = true
    }
  }
  goLook() {
    callAppFunc_goRoomList(6)
  }
  mounted() {
    this.getVipData()
  }
}
